
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$cikcik-web-primary: mat.define-palette(mat.$indigo-palette);
$cikcik-web-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$cikcik-web-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$cikcik-web-theme: mat.define-light-theme((
  color: (
    primary: $cikcik-web-primary,
    accent: $cikcik-web-accent,
    warn: $cikcik-web-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($cikcik-web-theme);

/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anek+Gujarati:wght@600&display=swap');
@import '@ctrl/ngx-emoji-mart/picker';
html,body {height: 100%; margin: 0 };
body { min-height:100%;  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal; }


